<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Leven met Dementie - Ziekenhuis</h1>
        <div class="row">
          <p class="intro-section-text" v-if="selectedYear === 2020 || selectedYear === 2021">
            Deze pagina is niet beschikbaar voor <em><b class="blue">2020 en 2021</b></em>.
          </p>
          <p class="intro-section-text" v-else>
            Onderstaand dashboard geeft informatie weer over de <b>ziekenhuiszorg</b> als onderdeel van de levenspadfase
            <b>'Leven met Dementie'</b>. Deze gegevens zijn uitsluitend op nationaal niveau beschikbaar.
            <!-- Het doel van dit dashboard is om de effectiviteit van de casemanager te meten. Aan de ene kant wordt de kwaliteit van leven met en zonder casemanager weergegeven.
          Aan de andere kant worden de kosten met en zonder casemanager vergeleken. Op deze manier kan een netwerk goed onderbouwde beslissingen nemen over het wel of niet aannemen van extra casemanagers.
          De kosten kunnen een indicatie zijn van over- of onderbehandeling, wat uiteindelijk ook een indicator voor kwaliteit kan zijn.
          Ook wordt inzichtelijk gemaakt in welk netwerk het hoogst scoort met betrekking tot kwaliteit van de zorg en hoe het geselecteerde netwerk zich verhoudt tot het landelijk gemiddelde zodat er van andere netwerken geleerd kan worden.  -->
          </p>
        </div>
      </div>
    </div>
    <div v-if="selectedYear !== 2020 && selectedYear !== 2021">
      <div class="row">
        <div class="col-sm-6 down">
          <card :cardId="'Percentage met minimaal één ziekenhuisopname'"
            :hovertext="'DIn deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'" :x="jaar"
            :y="perc_minstens_een_opname_table.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Percentage'"
            :tableHeight='400'>
            <template slot="card-header">Percentage met minimaal één ziekenhuisopname</template>
            <div slot="card-body">
              <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage mensen met dementie dat minimaal 1
                ziekenhuisopname heeft </p>
              <stacked-bar-chart-colors-x-t v-if="load.graphThree" :ytitle="'% met minimaal 1 opname'" :xtitle="'Jaar'"
                :name1="'Minimaal 1 opname'" :x1="jaar" :y1="perc_minstens_een_opname" :showlegend="false"
                :marginBottom="50" :tickangle='0' />
            </div>
          </card>
        </div>
        <div class="col-sm-6 down">
          <card :cardId="'Gemiddeld aantal ziekenhuisopnames'"
            :hovertext="'In deze grafiek wordt uitsluitend gekeken naar mensen met dementie die minimaal 1 ziekenhuis opname hebben gehad in het betreffende jaar.'"
            :x="jaar" :y="gemiddelde_opnames_table.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Percentage'"
            :tableHeight='400'>
            <template slot="card-header">Gemiddeld aantal ziekenhuisopnames</template>
            <div slot="card-body">
              <p class="graphtext">Onderstaande grafiek geeft inzicht in het gemiddeld aantal ziekenhuisopnames van mensen
                met dementie die ten minste 1x zijn opgenomen in het geselecteerde jaar </p>
              <stacked-bar-chart-colors-x-t v-if="load.graphThree" :ytitle="'Gemiddeld aantal opnames'" :xtitle="'Jaar'"
                :name1="'Gemiddeld aantal opnames'" :x1="jaar" :y1="gemiddelde_opnames" :showlegend="false"
                :marginBottom="50" :tickangle='0' />
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="`Type opname in ziekenhuis in ${selectedYear}`"
            v-if="selectedYear !== 2020 && selectedLevel !== 'landelijk'"
            :hovertext="'Type opname: dagopname, langdurige observatie of klinische opname. Het percentage wordt berekend als deel van het totaal aantal opnames in het geselecteerde jaar van de betreffende bevolkingsgroep. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51D78'"
            :x="jaar" :y="groep" :z="dag_table.map(value => `${value}%`)" :w="klinisch_table.map(value => `${value}%`)"
            :v="observaties_table.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Soort Opname'"
            :ztitle="'Dagopnamen'" :wtitle="'Klinische opnamen'" :vtitle="'Observaties'" :tableHeight='400'>
            <template slot="card-header">Type opname in ziekenhuis in {{ selectedYear }}</template>
            <div slot="card-body">
              <p class="graphtext">Onderstaande grafiek geeft inzicht in het type opname: het percentage dagopname,
                langdurige observatie of klinische opname van alle opnames in het geselecteerde jaar </p>
              <stacked-bar-chart-colors-x-t v-if="load.graphSix" :ytitle="'% soort opname'" :xtitle="'Bevolkingsgroep'"
                :tickangle='0' :name1="'Dagopname'" :x1="groep_dag" :y1="dag" :name2="'Klinische opname'"
                :x2="groep_klinisch" :y2="klinisch" :name3="'Langdurige observatie'" :x3="groep_observaties"
                :y3="observaties" />
            </div>
          </card>
          <card :cardId="'leven-met-dementie-ziekenhuis-3-1'" v-if="selectedYear === 2020">
            <template slot="card-header">Type opname in ziekenhuis in {{ selectedYear }} - niet beschikbaar voor 2020
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 down">
          <card :cardId="`Percentage acute opnames in ${selectedYear}`"
            v-if="selectedYear !== 2020 && selectedLevel !== 'landelijk'"
            :hovertext="'Specifiek wordt gekeken naar acute klinische opnames die als “urgent” zijn aangemerkt, en dus niet gepland waren als percentage van het totaal aantal klinische opnames. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84522NED/table?dl=51F68'"
            :x="groep_acuut" :y="perc_acuut_table.map(value => `${value}%`)" :xtitle="'Bevolkingsgroep'"
            :ytitle="'Percentage'" :tableHeight='300'>
            <template slot="card-header">Percentage acute opnames in {{ selectedYear }}</template>
            <div slot="card-body">
              <p class="graphtext">Deze grafiek geeft inzicht in het percentage klinische opnames dat als “urgent” is
                aangemerkt, en dus niet gepland was maar acuut.</p>
              <stacked-bar-chart-colors-x-t v-if="load.graphFive" :ytitle="'% acute klinische opnames'"
                :xtitle="'Bevolkingsgroep'" :name1="'% acute opnames'" :tickangle='0' :x1="groep_acuut" :y1="perc_acuut"
                :showlegend="false" />
            </div>
          </card>
          <card :cardId="'leven-met-dementie-ziekenhuis-4-1'" v-if="selectedYear === 2020">
            <template slot="card-header">Percentage acute opnames in {{ selectedYear }} - niet beschikbaar voor 2020
            </template>
          </card>
        </div>
        <div class="col-sm-6 down fluid">
          <card :cardId="`Gemiddelde ligduur bij klinische opname in ${selectedYear}`"
            v-if="selectedYear !== 2020 && selectedLevel !== 'landelijk'"
            :hovertext="'In deze grafiek wordt gekeken naar het gemiddeld aantal dagen dat iemand in het ziekenhuis ligt. Data over mensen met dementie komt uit de CBS microdata. De bron voor de data over de algemene bevolking is: https://opendata.cbs.nl/#/CBS/nl/dataset/84069NED/table?dl=2C798'"
            :x="groep_ligduur" :y="ligduur.map(value => `${value}`)" :xtitle="'Bevolkingsgroep'" :ytitle="'Aantal'"
            :tableHeight='300'>
            <template slot="card-header">Gemiddelde ligduur bij klinische opname in {{ selectedYear }}</template>
            <div slot="card-body">
              <p class="graphtext">Onderstaande grafiek geeft inzicht in het gemiddeld aantal dagen dat iemand in het
                ziekenhuis ligt (bij klinische opname).</p>
              <stacked-bar-chart-colors-numbers v-if="load.graphFour" :ytitle="'Gemiddelde ligduur'"
                :xtitle="'Bevolkingsgroep'" :name1="'Gemiddelde ligduur'" :x1="groep_ligduur" :y1="ligduur"
                :showlegend="false" />
            </div>
          </card>
          <card :cardId="'leven-met-dementie-ziekenhuis-5-1'" v-if="selectedYear === 2020">
            <template slot="card-header">Gemiddelde ligduur bij klinische opname in {{ selectedYear }} - niet beschikbaar
              voor 2020
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="`Top 10 hoofddiagnosen mensen met dementie in ${selectedYear}`"
            v-if="selectedYear !== 2020 && selectedLevel !== 'landelijk'"
            :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen met dementie gekeken. De data komt uit de CBS microdata. Het gaat over de ICD-10 code zoals gerapporteerd in de LBZ-data.'"
            :x="aantal_dementie" :y="diagnose_dementie.map(value => `${value}`)" :xtitle="'Aantal'"
            :ytitle="'De top 10 hoofddiagnosen'" :tableHeight='430'>
            <template slot="card-header">Top 10 hoofddiagnosen mensen met dementie in {{ selectedYear }}</template>
            <div slot="card-body">
              Deze grafiek geeft de top 10 hoofddiagnosen weer voor mensen met dementie in {{ selectedYear }}.
              <horizontal-bar-chart-numbers v-if="load.graphSeven" :round="1"
                :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen met dementie'" :x="aantal_dementie"
                :y="diagnose_dementie" :marginleft="300" />
            </div>
          </card>
          <card :cardId="'leven-met-dementie-ziekenhuis-6-1'" v-if="selectedYear === 2020">
            <template slot="card-header">Top 10 hoofddiagnosen mensen met dementie {{ selectedYear }} - niet beschikbaar
              voor 2020
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 80+ in ${selectedYear}`"
            v-if="selectedLevel !== 'landelijk'"
            :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen boven de 65 jaar gekeken. Bron voor deze data is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51F3B'"
            :x="aantal_80" :y="diagnose_80.map(value => `${value}`)" :xtitle="'Aantal'"
            :ytitle="'De top 10 hoofddiagnosen'" :tableHeight='430'>
            <template slot="card-header">Top 10 hoofddiagnosen mensen algemene bevolking 80+ in
              {{ selectedYear }}</template>
            <div slot="card-body">
              Deze grafiek geeft de top 10 hoofddiagnosen weer voor iedereen in de algemene bevolking ouder dan 80 jaar in
              {{ selectedYear }}.
              <horizontal-bar-chart-numbers v-if="load.graphSeven" :round="1"
                :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen ouder dan 80'" :x="aantal_80" :y="diagnose_80"
                :marginleft='300' />
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="`Top 10 hoofddiagnosen mensen algemene bevolking 65+ in ${selectedYear}`"
            v-if="selectedLevel !== 'landelijk'"
            :hovertext="'In deze grafiek wordt naar de totale jaarlijkse ziekenhuisopnames van mensen boven de 65 jaar gekeken. Bron voor deze data is: https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84069NED/table?dl=51F43'"
            :x="aantal_65" :y="diagnose_65.map(value => `${value}`)" :xtitle="'Aantal'"
            :ytitle="'De top 10 hoofddiagnosen'" :tableHeight='430'>
            <template slot="card-header">Top 10 hoofddiagnosen mensen algemene bevolking 65+ in
              {{ selectedYear }}</template>
            <div slot="card-body">
              Deze grafiek geeft de top 10 hoofddiagnosen weer voor iedereen in de algemene bevolking ouder dan 65 jaar in
              {{ selectedYear }}.
              <horizontal-bar-chart-numbers v-if="load.graphSeven" :round="1"
                :xtitle="'Aantal opnames met hoofddiagnose per 100 mensen ouder dan 65'" :x="aantal_65" :y="diagnose_65"
                :marginleft="300" />
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-sm-6">
        <card :hovertext="'Deze data komt uit de Dementiemonitor Mantelzorg'">
          <template slot="card-header">CONCEPT Kwaliteit van leven</template>
          <div slot="card-body">
            <p class="graphtext"> In dit diagram worden verschillende factoren weergegeven die van invloed zijn op de kwaliteit van leven. De scores worden gegeven voor zowel mensen met een casemanager als mensen zonder casemanager. </p>
            <grouped-bar-chart :data1="[{ type: 'bar', name:'Met Casemanager',
            x: ['Plezier','Mantelzorger werkt','Ziekenhuisopnames','Slaapproblemen', 'Financiële Problemen'], y:[8,7,3,3,4], marker: {
            color: 'rgba(180,180,180,1.00)'}},{ type: 'bar', name: 'Zonder Casemanager',
            x: ['Plezier','Mantelzorger werkt','Ziekenhuisopnames','Slaapproblemen', 'Financiële Problemen'], y:[7,6,6,5,7], marker: {
            color: 'rgba(180,180,180,0.80)'}}]"/>
          </div>
        </card>
      </div>

      <div class="col-sm-6">
        <card :hovertext="'Deze gegevens worden uit de Dementiemonitor Mantelzorg gehaald'">
          <template slot="card-header">CONCEPT Kwaliteit van leven netwerken</template>
          <div slot="card-body">
            <p class="graphtext"> In onderstaand diagram worden dezelfde factoren weergegeven die informatie geven over de kwaliteit van leven.
            Echter kan hierin de vergelijking worden gemaakt tussen het beste netwerk, het geselecteerde netwerk en het landelijk gemiddelde. </p>
            <line-chart :data1="[{ mode: 'markers', name:'Hoogstscorende Netwerk',
            x: ['Plezier','Mantelzorger werkt','Ziekenhuisopnames','Slaapproblemen', 'Financiële Problemen'], y:[8,7,3,3,4], marker: {
            color: '#357F63'}},
            { mode: 'markers', name:'Mijn Netwerk',
            x: ['Plezier','Mantelzorger werkt','Ziekenhuisopnames','Slaapproblemen', 'Financiële Problemen'], y:[6,8,4,6,5], marker: {
            color: 'rgba(180,180,180,1.0)'}},
            { mode: 'markers', name: 'Landelijk gemiddelde',
            x: ['Plezier','Mantelzorger werkt','Ziekenhuisopnames','Slaapproblemen', 'Financiële Problemen'], y:[7,6,6,5,7], marker: {
            color: 'rgba(180,180,180,0.6)'}}]"/>
          </div>
        </card>
      </div>
    </div> -->

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>

<script>

import StackedBarChartColorsXT from '../components/StackedBarChartColorsXTitle'
import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
// import GroupedBarChart from '../components/GroupedBarChart'
// import LineChart from '../components/LineChart'
import HorizontalBarChartNumbers from '../components/HorizontalBarChartNumbers'
// import HorizontalBarChart from '../components/HorizontalBarChart'
// import Waterfall from '../components/Waterfall'
import Card from '../components/Card'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

import { mapGetters } from 'vuex'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Ziekenhuis',
  },
  components: { Card, StackedBarChartColorsXT, StackedBarChartColorsNumbers, HorizontalBarChartNumbers, Footer },
  data() {
    return {
      load: {
        graphThree: false,
        graphFour: false,
        graphFiv: false,
        graphSix: false,
        graphSeven: false,
        graphEight: false
      },
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      jaar: [],
      perc_minstens_een_opname: [],
      gemiddelde_opnames: [],
      ligduur: [],
      groep_ligduur: [],
      perc_acuut: [],
      perc_acuut_table: [],
      groep_acuut: [],
      dag: [],
      groep_dag: [],
      klinisch: [],
      groep_klinisch: [],
      observaties: [],
      groep_observaties: [],
      aantal_dementie: [],
      diagnose_dementie: [],
      aantal_65: [],
      diagnose_65: [],
      aantal_80: [],
      diagnose_80: [],
      perc_minstens_een_opname_table: [],
      gemiddelde_opnames_table: [],
      soort_opname: [],
      dag_table: [],
      observaties_table: [],
      klinisch_table: [],
      groep: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData() {
      this.$http.get('/api/opnames/get_data_dementie/').then(
        (response) => {
          this.jaar = response.data.filter(x => (x.jaar !== 2015) && (x.jaar !== 2016)).map(d => d.jaar)
          this.perc_minstens_een_opname = response.data.map(d => d.perc_een_opname)
          this.perc_minstens_een_opname_table = response.data.map(d => (d.perc_een_opname * 100).toFixed(1))
          this.gemiddelde_opnames = response.data.map(d => d.gemiddelde_opnames.toFixed(2))
          this.gemiddelde_opnames_table = response.data.map(d => (d.gemiddelde_opnames * 100).toFixed(1))
          this.load.graphThree = true
        }
      ),
        this.$http.get(`/api/opnames/get_data_ligduur/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.ligduur = response.data.map(d => d.ligduur_langdurig_klinisch.toFixed(2))
            this.groep_ligduur = response.data.map(d => d.groep)
            this.load.graphFour = true
          }
        ),
        this.$http.get(`/api/opnames/get_data_acuut/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.perc_acuut = response.data.map(d => d.perc_acuut)
            this.perc_acuut_table = response.data.map(d => d.perc_acuut)
            this.groep_acuut = response.data.map(d => d.groep)
            this.load.graphFive = true
          }
        ),
        this.$http.get(`/api/opname_soort/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.groep_dag = response.data.filter(x => x.soort_opname == 'Dagopnamen').map(d => d.groep)
            this.dag = response.data.filter(x => x.soort_opname == 'Dagopnamen').map(d => (d.percentage))
            this.groep_klinisch = response.data.filter(x => x.soort_opname == 'Klinische opnamen').map(d => d.groep)
            this.klinisch = response.data.filter(x => x.soort_opname == 'Klinische opnamen').map(d => (d.percentage))
            this.groep_observaties = response.data.filter(x => x.soort_opname == 'Observaties').map(d => d.groep)
            this.observaties = response.data.filter(x => x.soort_opname == 'Observaties').map(d => (d.percentage))

            this.dag_table = response.data.filter(x => x.soort_opname == 'Dagopnamen').map(d => (d.percentage * 100).toFixed(1))
            this.klinisch_table = response.data.filter(x => x.soort_opname == 'Klinische opnamen').map(d => (d.percentage * 100).toFixed(1))
            this.observaties_table = response.data.filter(x => x.soort_opname == 'Observaties').map(d => (d.percentage * 100).toFixed(1))
            this.soort_opname = [...new Set(response.data.map(x => x.soort_opname))];
            this.groep = [...new Set(response.data.map(x => x.groep))];
            this.load.graphSix = true
          }
        ),
        this.$http.get(`/api/hoofddiagnose/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.rank - a.rank)
            this.diagnose_dementie = response.data.filter(x => x.groep == 'Mensen met dementie').map(d => d.hoofddiagnose).slice(-10)
            this.aantal_dementie = response.data.filter(x => x.groep == 'Mensen met dementie').map(d => (d.percentage * 100).toFixed(2)).slice(-10)
            this.diagnose_65 = response.data.filter(x => x.groep == 'Algemene bevolking 65+').map(d => d.hoofddiagnose).slice(-10)
            this.aantal_65 = response.data.filter(x => x.groep == 'Algemene bevolking 65+').map(d => (d.percentage * 100).toFixed(2)).slice(-10)
            this.diagnose_80 = response.data.filter(x => x.groep == 'Algemene bevolking 80+').map(d => d.hoofddiagnose).slice(-10)
            this.aantal_80 = response.data.filter(x => x.groep == 'Algemene bevolking 80+').map(d => (d.percentage * 100).toFixed(2)).slice(-10)
            this.load.graphSeven = true
          }
        )
    }
  },
  mounted() {
    this.loadAllData()
  }
}

</script>
<style scoped>.graphtext-big {
  height: 60px;
}

.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}</style>
